import useSound from "use-sound";

import sound1 from "../sounds/1.mp3";
import sound2 from "../sounds/2.mp3";
import sound3 from "../sounds/3.mp3";
import sound4 from "../sounds/4.mp3";
import sound5 from "../sounds/5.mp3";
import sound6 from "../sounds/6.mp3";
import sound7 from "../sounds/7.mp3";
import sound8 from "../sounds/8.mp3";
import sound9 from "../sounds/9.mp3";
import sound10 from "../sounds/10.mp3";
import sound20 from "../sounds/20.mp3";
import sound30 from "../sounds/30.mp3";
import sound40 from "../sounds/40.mp3";
import sound50 from "../sounds/50.mp3";
import sound60 from "../sounds/60.mp3";
import sound70 from "../sounds/70.mp3";
import sound80 from "../sounds/80.mp3";
import sound90 from "../sounds/90.mp3";
import sound100 from "../sounds/100.mp3";

const UseCountSound = () => {
  //   const ret: { [key: string]: any } = {};
  //   for (let i = 1; i <= 5; i++) {
  //     const sound = await import(`../sounds/${i}.mp3`);
  //     ret[`sound${i}`] = useSound(sound);
  //   }
  //   console.log(ret);
  //   return ret;
  const [play1] = useSound(sound1);
  const [play2] = useSound(sound2);
  const [play3] = useSound(sound3);
  const [play4] = useSound(sound4);
  const [play5] = useSound(sound5);
  const [play6] = useSound(sound6);
  const [play7] = useSound(sound7);
  const [play8] = useSound(sound8);
  const [play9] = useSound(sound9);
  const [play10] = useSound(sound10);
  const [play20] = useSound(sound20);
  const [play30] = useSound(sound30);
  const [play40] = useSound(sound40);
  const [play50] = useSound(sound50);
  const [play60] = useSound(sound60);
  const [play70] = useSound(sound70);
  const [play80] = useSound(sound80);
  const [play90] = useSound(sound90);
  const [play100] = useSound(sound100);

  return {
    play1,
    play2,
    play3,
    play4,
    play5,
    play6,
    play7,
    play8,
    play9,
    play10,
    play20,
    play30,
    play40,
    play50,
    play60,
    play70,
    play80,
    play90,
    play100,
  };
};
export default UseCountSound;
