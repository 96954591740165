import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import useCountSound from "./hooks/UseCountSound";

import soundFinish from "./sounds/finish.mp3";
import "./App.css";

function App() {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [countInterval, setCountInterval] = useState<string>("1");
  const [intervalId, setIntervalId] = useState<any>();
  const [finishCount, setFinishCount] = useState<string>("10");
  const [showButton, setShowButton] = useState<boolean>(true);
  const [playFinish] = useSound(soundFinish);
  const sounds: { [key: string]: Function } = useCountSound();
  const getCountSound = (count: number) => {
    if (count === 0) return null;
    if (count % 100 === 0) return count;
    if (count % 10 === 0) return count - Math.floor(count / 100) * 100;
    return count % 10;
  };
  useEffect(() => {
    const countSound = getCountSound(count);
    const soundKey = `play${countSound}`;
    if (!sounds[soundKey]) return;
    sounds[soundKey]();
    if (parseInt(finishCount) <= count) {
      resetCounter();
      playFinish();
    }
  }, [count]);
  const startCounter = () => {
    setIsRunning(true);
    const localIntervalId = setInterval(() => {
      setCount((t) => t + 1);
    }, parseFloat(countInterval) * 1000);
    setIntervalId(localIntervalId);
  };
  const resetCounter = () => {
    setCount(0);
    stopCounter();
    setShowButton(true);
  };
  const stopCounter = () => {
    setIsRunning(false);
    clearInterval(intervalId);
  };
  return (
    <div className="App">
      <div id="screen">
        <div id="screen-center">
          <div id="display">
            <div id="counter">{count}</div>
          </div>
          <div className="button-area">
            {showButton && (
              <button
                id="execute"
                onClick={
                  isRunning ? (e) => stopCounter() : (e) => startCounter()
                }
              >
                {isRunning ? "Stop" : "Start"}
              </button>
            )}
            <button id="reset" onClick={(e) => resetCounter()}>
              Reset
            </button>
            <div id="setting">
              <div id="interval-second">
                間隔(秒)
                <input
                  type="text"
                  value={countInterval}
                  onChange={(e) => setCountInterval(e.target.value)}
                />
              </div>
              <div id="finish-count">
                ゴール(回)
                <input
                  type="text"
                  value={finishCount}
                  onChange={(e) => setFinishCount(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
